module.exports = {
  //
  // Change information to your own:
  //
  // Site Info:
  title: 'Cult of the Turtle',
  description: `Joe Tortuga's musing on life,tech and gaming`,
  siteUrl: 'https://www.cultoftheturtle.com/',
  footerText: '**2009-2019**<br><br>Joe Tortuga, Cult of the Turtle', // You can use Markdown markup here

  // Blog Author Info:
  author: 'Joe Tortuga',
  authorImage:
    'https://pbs.twimg.com/profile_images/896808261386727424/QSbnkq9c_bigger.jpg',
  authorBio:
    "Hello, I'm Joe Tortuga<br/>Dual class Bisexual Rogue/Social Justice Bard. Kinky, poly, cis, programmer-writer. He/him.", // You can use Markdown markup here
  social: {
    twitter: 'joetortuga',
    github: 'joetortuga',
    reddit: 'joetortuga'
  },

  // Services:
  googleAnalyticsTrackingId: `ADD YOUR TRACKING ID HERE`,

  // Site Style:
  lightTheme: 'grommet', // grommet | dark | base | dxc | aruba
  // leave darkTheme blank if you don’t need a theme switch
  darkTheme: 'dark', // '' | grommet | dark | base | dxc | aruba
  font: '', // Roboto
  fontHeadings: '', // Merriweather
  brandColor: '' // #7D4CDB
};
